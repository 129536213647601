/* Global styles */
:root {
    --primary-color: #212121;
    --secondary-color: #555;
    --font-family-sans: "Open Sans", sans-serif;
    --font-family-heading: "Montserrat", sans-serif;
    --line-height: 26px;
    --font-size: 15px;
}

*, *::before, *::after {
    box-sizing: border-box;
}

body {
    background: #fff;
    color: var(--secondary-color);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    font-family: var(--font-family-sans);
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased !important;
    line-height: var(--line-height);
    font-size: var(--font-size);
    border: 0;
    margin: 0;
    padding: 0;
}

p {
    font-family: var(--font-family-sans);
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased !important;
    line-height: var(--line-height);
    font-size: var(--font-size);
}

h1, h2, h3, h4, h5, h6 {
    color: var(--primary-color);
    font-weight: 700;
    font-family: var(--font-family-heading);
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased !important;
}

h1 {
    font-size: 36px;
    text-transform: uppercase;
    line-height: 48px;
}

h2 {
    font-size: 28px;
    line-height: 36px;
    text-transform: uppercase;
    letter-spacing: -1px;
}

h3 {
    font-size: 24px;
    text-transform: uppercase;
    letter-spacing: -0.5px;
}

h4 {
    font-size: 18px;
    line-height: 28px;
    text-transform: uppercase;
    letter-spacing: -0.2px;
}

h5 {
    font-size: 14px;
    line-height: 24px;
}

html {
    overflow-x: hidden !important;
    width: 100%;
    height: 100%;
    position: relative;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

.body-inner {
    position: relative;
    overflow: hidden;
}

a:link, a:visited {
    text-decoration: none;
}

a {
    color: inherit;
    transition: 0.2s ease;
}

    a:hover {
        text-decoration: none;
        color: #000;
    }

    a:focus {
        outline: 2px dashed #000;
        outline-offset: 2px;
    }

hr {
    background-color: #e7e7e7;
    border: 0;
    height: 1px;
    margin: 50px 0;
    opacity: 0.65;
}

@media (max-width: 36rem) {
    h1 {
        font-size: 1.75rem;
        line-height: 2.25rem;
    }

    h2 {
        font-size: 1.5rem;
        line-height: 2rem;
    }

    h3 {
        font-size: 1.25rem;
        line-height: 1.75rem;
    }

    h4 {
        font-size: 1rem;
        line-height: 1.5rem;
    }
}

/* backgrounds */
.solid-bg {
    background: #f9f9f9;
}

.dark-bg {
    background: #252525;
    color: #fff;
}

    .dark-bg h2,
    .dark-bg h3 {
        color: #fff;
    }

/* Login Box*/
.center-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75vh;
}

.login-box {
    width: 300px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Sponsors */
.container-sponsor {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0.5em;
}

.sponsor {
    cursor: pointer;
    border: 5px solid transparent;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

    .sponsor:hover {
        transform: scale(1.10);
        animation: highlight 2s ease-out;
    }

.big-sponsor {
    grid-row: auto / span 3;
    grid-column: auto / span 3;
}

.small-sponsor {
    grid-row: auto / span 1;
    grid-column: auto / span 1;
}

.sponsor img {
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    width: 100%;
    height: 100%;
    -webkit-object-fit: inherit;
}

    .sponsor img:hover {
        transform: scale(1.05);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    }


@keyframes highlight {
    0% {
        box-shadow: 0 0 10px 5px #9ecaed;
    }

    100% {
        box-shadow: 0 0 0px 0px #9ecaed;
    }
}

/* Title */
.title {
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 20px;
}

.section-title {
    font-size: 18px;
    line-height: 28px;
    margin: 0;
    text-transform: uppercase;
    font-weight: 300;
}

.section-sub-title {
    font-weight: 900;
    font-size: 36px;
    line-height: 46px;
    margin: 0 0 60px;
    color: #212121;
}

@media (max-width: 575px) {
    .section-sub-title {
        font-size: 28px;
    }
}

.column-title {
    margin: 0 0 40px;
}


/* forms */
.form-control:focus {
    box-shadow: none;
    border: 1px solid #ffb600;
}

.form-select:focus {
    box-shadow: none;
    border: 1px solid #ffb600;
}

@media (max-width: 768px) {
    .row .form-floating {
        margin-bottom: 0;
    }

    .row > div:last-child .form-floating {
        margin-bottom: 0;
    }

    .row.mb-3:last-child {
        margin-bottom: 0;
    }

    /* Add margin-bottom for all items except the last child */
    .row > div:not(:last-child) .form-floating {
        margin-bottom: 1rem;
    }
}

@media screen and (max-width: 575px) {
    #rc-imageselect, .g-recaptcha {
        transform: scale(0.77);
        -webkit-transform: scale(0.77);
        transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
    }
}

/* Form Validation */
.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

.alert hr {
    background: initial;
    margin: initial;
    border-top: 1px solid;
    height: initial;
    margin-bottom: 1rem;
    opacity: 0.2;
}

/*blockquote*/
blockquote {
    padding: 25px;
    margin: 20px 0;
    background: #f9f9f9;
    position: relative;
    border-left: 3px solid #ffb600;
    padding-left: 40px;
}

    blockquote.text-center {
        border-top: 3px solid #ffb600;
        border-left: 0;
        padding-left: 25px;
        padding-top: 50px;
    }

        blockquote.text-center:before {
            left: 50%;
            top: 10px;
            transform: translateX(-50%);
        }

    blockquote.text-right {
        border-right: 3px solid #ffb600;
        padding-right: 40px;
        border-left: 0;
        padding-left: 25px;
    }

        blockquote.text-right:before {
            left: auto;
            right: 5px;
        }

    blockquote:before {
        content: "\f10d";
        font-family: "Font Awesome 5 Free";
        position: absolute;
        font-size: 28px;
        color: #E5E5E5;
        top: 5px;
        left: 5px;
        font-weight: 700;
        z-index: 0;
    }

    blockquote p {
        font-size: 14px;
        line-height: 22px;
        position: relative;
        z-index: 1;
        font-style: italic;
        margin-bottom: 0;
    }

/* Buttons */
.btn:active, .btn:focus {
    box-shadow: none !important;
}

.button-container .btn:not(:last-child) {
    margin-right: 5px;
}

.button-container-selection {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

    .button-container-selection > button {
        margin: 0.3125rem;
        min-width: 11rem;
        height: 3.125rem;
        font-size: 0.875rem;
    }

@media (max-width: 768px) {
    .button-container-selection {
        align-items: center;
    }
}

.btn-primary,
.btn-dark {
    border: 0;
    border-radius: 3px;
    padding: 12px 20px 10px;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    font-family: "Montserrat", sans-serif;
    transition: 350ms;
    font-size: 14px;
}

.btn-primary-admin,
.btn-dark-admin {
    border: 0;
    border-radius: 3px;
    background: #ffb600;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    font-family: "Montserrat", sans-serif;
    transition: 350ms;
    font-size: 14px;
}

.btn-primary-admin-dropdown {
    --bs-btn-color: #fff;
    --bs-btn-bg: #ffb600;
    --bs-btn-border-color: #ffb600;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #ff9a00;
    --bs-btn-hover-border-color: #ff9a00;
    --bs-btn-focus-shadow-rgb: 255, 182, 0;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #ff9a00;
    --bs-btn-active-border-color: #ff9a00;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #ffb600;
    --bs-btn-disabled-border-color: #ffb600;
}

.btn-danger-admin {
    border: 0;
    border-radius: 3px;
    background: #dc3545;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    font-family: "Montserrat", sans-serif;
    transition: 350ms;
    font-size: 14px;
}

.btn-check-admin {
    border: 0;
    border-radius: 3px;
    background: #28A745;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    font-family: "Montserrat", sans-serif;
    transition: 350ms;
    font-size: 14px;
}

@media (max-width: 767px) {
    .btn-primary,
    .btn-dark {
        font-size: 13px;
    }
}

.btn-white.btn-primary {
    background: #fff;
    color: #ffb600;
}

.btn-primary {
    background: #ffb600;
}

.btn-dark {
    background: #23282d;
}

    .btn-primary:hover,
    .btn-dark:hover,
    .btn-white.btn-primary:hover {
        background: #111;
        color: #fff;
    }

        .btn-primary:hover:active, .btn-primary:hover:focus,
        .btn-dark:hover:active,
        .btn-dark:hover:focus,
        .btn-white.btn-primary:hover:active,
        .btn-white.btn-primary:hover:focus {
            color: #fff;
            background-color: #111 !important;
        }

/* Navbar */
.navbar-fixed {
    z-index: 9999;
    position: fixed;
    width: 100%;
    top: 0;
}

ul.navbar-nav > li > a {
    padding: 15px 0 !important;
    color: #fff;
}

@media (max-width: 991px) {
    ul.navbar-nav > li > a {
        padding: 1px 0 !important;
    }
}

ul.navbar-nav > li:hover > a,
ul.navbar-nav > li.active > a {
    color: #ffb600 !important;
}

.logo img {
    width: auto;
    height: 35px;
}

/* header right */
.header-right {
    float: right;
}

/* Bootstrap nav overrides */
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover,
.nav-tabs > li > a:hover,
.nav-tabs > li > a {
    border: 0;
}

.nav > li > a:focus,
.nav > li > a:hover {
    background: none;
}

a[href^=tel] {
    color: inherit;
    text-decoration: none;
}

/* Main navigation */
.navbar-toggler {
    float: left;
    margin-top: 10px;
    padding: 12px;
}

.navbar {
    border-radius: 0;
    border: 0;
    margin-bottom: 0;
}

.navbar-toggler {
    margin: 10px 0;
    padding: 6px;
    border-radius: 0;
    font-size: 1rem;
    background: #ffb600;
}

    .navbar-toggler:focus {
        outline: 0;
    }

.navbar-dark .navbar-toggler-icon,
.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

ul.navbar-nav > li:hover > a,
ul.navbar-nav > li.active > a {
    color: #ffb600;
    position: relative;
}

ul.navbar-nav > li {
    padding: 0 13px;
    position: relative;
}

@media (max-width: 1200px) {
    ul.navbar-nav > li {
        padding: 0 9px;
    }

        ul.navbar-nav > li .nav-link {
            font-size: 12px;
        }
}

ul.navbar-nav > li:last-child {
    padding-right: 0;
}

    ul.navbar-nav > li:last-child:after {
        background: none;
    }

ul.navbar-nav > li > a:hover,
ul.navbar-nav > li > a:focus {
    background: none;
}

ul.navbar-nav > li > a i {
    font-weight: 700;
}

ul.navbar-nav > li > a:after,
ul.navbar-nav > li > a:after {
    display: none;
}

ul.navbar-nav > li > a {
    font-family: "Montserrat", sans-serif;
    color: #fff !important;
    text-rendering: optimizeLegibility;
    font-weight: 700 !important;
    text-transform: uppercase;
    letter-spacing: -0.2px;
    font-size: 14px;
    margin: 0;
    line-height: 40px;
    padding: 30px 0;
    transition: 350ms;
}

.navbar-light ul.navbar-nav > li > a {
    font-size: 13px;
}

/* Top Bar */
.top-bar {
    padding: 12px 0;
    background: #ebebeb;
    position: relative;
}

ul.top-info {
    list-style: none;
    margin: 0;
    padding: 0;
}

    ul.top-info li {
        display: inline-block;
        margin-left: 0;
        padding-right: 15px;
    }

        ul.top-info li i {
            font-size: 18px;
            margin-right: 5px;
            top: 2px;
            position: relative;
        }

        ul.top-info li p.info-text {
            margin: 0;
            display: inline-block;
            line-height: normal;
        }

/* Dropdown */
.dropdown-submenu {
    position: relative;
}

    .dropdown-submenu > .dropdown-menu {
        top: 0;
        left: 100%;
        margin-top: -2px;
        border-radius: 0;
    }

    .dropdown-submenu > a:after {
        display: block;
        content: "\f105";
        font-family: "Font Awesome 5 Free";
        float: right;
        margin-top: 0;
        margin-right: -5px;
        border: 0;
    }

    .dropdown-submenu:hover > a:after {
        border-left-color: #fff;
    }

.dropdown-menu {
    text-align: left;
    background: #f8f9fa;
    z-index: 100;
    min-width: 200px;
    border-radius: 4px;
    border: 1px solid #dee2e6;
    border-top: 4px solid #ffb600;
    padding: 10px 20px;
    margin: 0;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    transition: all 0.3s ease;
}

    .dropdown-menu a {
        color: #212529;
        transition: all 0.3s ease;
    }

        .dropdown-menu a:hover {
            color: #ffffff;
        }

@media (max-width: 991px) {
    .dropdown-menu {
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.05);
    }
}

.navbar-nav > li > .dropdown-menu a {
    background: none;
}

.dropdown-menu li a {
    font-family: "Montserrat", sans-serif;
    display: block;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    line-height: normal;
    text-decoration: none;
    padding: 15px 0;
    letter-spacing: 0.3px;
    border-bottom: 1px solid #e5e5e5;
    color: #333333;
}

@media (max-width: 991px) {
    .dropdown-menu li a {
        padding: 12px 0;
    }
}

.dropdown-menu li:last-child > a {
    border-bottom: 0;
}

.dropdown-menu li a:hover,
.dropdown-menu li a:focus {
    color: #ffb600;
}

@media (min-width: 991px) {
    ul.nav li.dropdown:hover ul.dropdown-menu {
        display: block;
    }
}

@media (min-width: 991px) {
    ul.nav li.dropdown ul.dropdown-menu li.dropdown-submenu .dropdown-menu {
        left: 100%;
        top: 0;
        display: none;
    }

    ul.nav li.dropdown ul.dropdown-menu li.dropdown-submenu:hover .dropdown-menu {
        display: block;
    }
}

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus,
.dropdown-menu > .active > .dropdown-menu > .active > a {
    background: none;
    color: #ffb600;
}

/* Slider */
.banner-carousel .banner-carousel-item {
    background: center / contain no-repeat;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

    .banner-carousel .banner-carousel-item img {
        width: 100%;
        height: auto;
        max-height: 100%;
        object-fit: contain;
    }


.slider-content {
    position: relative;
    height: 100%;
    width: 100%;
}

.slide-title-box {
    font-size: 16px;
    line-height: 39px;
    background: #ffb600;
    color: #fff;
    display: inline-block;
    padding: 0 15px;
    margin: 0 0 10px;
}

.slide-title {
    font-size: 30px;
    line-height: 36px;
    font-weight: 300;
    color: #fff;
    margin: 20px 0 10px;
}

@media (max-width: 991px) {
    .slide-title {
        font-size: 22px;
    }
}

@media (max-width: 575px) {
    .slide-title {
        font-size: 16px;
    }
}

.slide-sub-title {
    font-style: normal;
    font-size: 60px;
    line-height: 58px;
    margin: 20px 0;
    color: #fff;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: -1px;
}

@media (max-width: 991px) {
    .slide-sub-title {
        font-size: 46px;
    }
}

@media (max-width: 575px) {
    .slide-sub-title {
        font-size: 30px;
        line-height: 30px;
    }
}

.slider-text {
    display: table;
    vertical-align: bottom;
    color: #fff;
    padding-left: 40%;
    width: 100%;
    padding-bottom: 0;
    padding-top: 20px;
}

    .slider-text .slide-head {
        font-size: 36px;
        color: #0052a5;
        position: relative;
    }

.slider-img {
    display: block;
    position: absolute;
    top: -80px;
    left: 20%;
    width: 314px;
    max-height: 100%;
}

    .slider-img img {
        display: block;
        position: relative;
        max-height: 100%;
        width: auto;
    }

.slider.btn {
    margin: 15px 5px 0;
    border: 2px solid transparent;
}

@media (max-width: 575px) {
    .slider.btn {
        font-size: 12px;
    }
}

.slider.border {
    background: none;
    border: 2px solid #ffb600 !important;
}

    .slider.border:hover {
        background: #ffb600;
        border: 2px solid transparent;
    }

/* Carousel control */
.banner-carousel .carousel-control {
    position: absolute;
    top: 50%;
    z-index: 5;
    display: inline-block;
    transform: translateY(-50%);
    background-color: transparent;
    opacity: 0;
    filter: alpha(opacity=0);
    text-shadow: none;
    transition: all 0.25s ease;
    padding: 0;
    outline: 0;
    border: 0;
}

@media (max-width: 575px) {
    .banner-carousel .carousel-control {
        display: none !important;
    }
}

.banner-carousel .slick-dots {
    opacity: 0;
    filter: alpha(opacity=0);
    bottom: 60px;
}

.slick-dots li button::before {
    font-size: 12px;
}

.slick-dots li button:hover:before, .slick-dots .slick-dots li button:focus:before,
.slick-dots li.slick-active button:before {
    opacity: 1;
    color: #ffb600;
}

.slick-slide {
    outline: 0;
}

.banner-carousel:hover .carousel-control,
.banner-carousel:hover .carousel-control,
.banner-carousel:hover .slick-dots {
    opacity: 1;
    filter: alpha(opacity=100);
}

.banner-carousel .carousel-control.left {
    left: 20px;
}

.banner-carousel .carousel-control.right {
    right: 20px;
}

.banner-carousel .carousel-control i {
    background: rgba(0, 0, 0, 0.3);
    color: #fff;
    line-height: 58px;
    width: 60px;
    height: 60px;
    font-size: 22px;
    border-radius: 0;
    transition: all 500ms ease;
}

    .banner-carousel .carousel-control i:hover {
        background: #ffb600;
        color: #fff;
    }

/*-- Animation */
.banner-carousel [data-animation-in] {
    opacity: 0;
}

.box-slider-content {
    top: 50%;
    padding: 0;
    position: absolute;
    width: 75%;
    transform: translateY(-50%);
}

@media (max-width: 767px) {
    .box-slider-content {
        top: auto;
        width: calc(100% - 40px);
    }
}

.box-slider-text {
    background: #111;
    background: rgba(0, 0, 0, 0.65);
    display: inline-block;
    padding: 20px 30px;
    max-width: 650px;
}

.box-slide-title {
    font-size: 18px;
    font-weight: 300;
    margin: 0;
    color: #fff;
}

@media (max-width: 767px) {
    .box-slide-title {
        font-size: 16px;
    }
}

.box-slide-sub-title {
    font-size: 36px;
    margin: 8px 0 10px;
    color: #fff;
}

@media (max-width: 767px) {
    .box-slide-sub-title {
        font-size: 22px;
    }
}

.box-slide-description {
    color: #fff;
    word-wrap: break-word;
}

@media (max-width: 767px) {
    .box-slide-description {
        font-size: 12px;
        word-wrap: break-word;
    }
}

.box-slider-text .btn {
    padding: 10px 20px;
    word-wrap: break-word;
}

.box-slide.owl-theme .owl-controls {
    margin: -20px;
}

/* Call to action */
.call-to-action-box {
    background: #ffb600;
    padding: 2rem;
}

.call-to-action-title {
    color: #fff;
    margin: 0;
    padding: 1.5rem 0;
    line-height: 2.25rem;
    font-size: 1.375rem;
    text-transform: uppercase;
}

.call-to-action-btn-box {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.call-to-action-btn {
    min-height: 2rem;
    flex: 1 0 auto;
}

@media (max-width: 992px) {
    .call-to-action-title {
        text-align: center;
    }

    .call-to-action-box {
        flex-direction: column;
    }
}

/* Intro */
.ts-intro {
    padding-right: 20px;
}

.into-title {
    font-weight: 300;
    text-transform: uppercase;
    font-size: 18px;
    line-height: normal;
    margin: 0;
}

.into-sub-title {
    font-weight: 900;
    text-transform: uppercase;
    font-size: 32px;
    line-height: normal;
    margin: 10px 0;
}

/* Icon component */
.iconcomponent-container {
    max-width: 60%;
    margin: 0 auto;
    padding: 0 15px;
}

.iconcomponent-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
}

    .iconcomponent-box .iconcomponent-icon svg {
        font-size: 36px;
        color: #ffb600;
    }

    .iconcomponent-box .iconcomponent-box-content {
        margin-bottom: 30px;
    }

        .iconcomponent-box .iconcomponent-box-content h3 {
            font-size: 16px;
            margin-bottom: 10px;
            line-height: 28px;
        }

@media (max-width: 767px) {
    .iconcomponent-box {
        margin-bottom: 20px;
    }
}

/*-- Page header banner --*/
.banner-area {
    position: relative;
    min-height: 300px;
    color: #fff;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
}

@media (max-width: 767px) {
    .banner-area {
        min-height: 200px;
    }
}

@media (max-width: 575px) {
    .banner-area {
        min-height: 200px;
    }
}

.banner-title {
    color: #fff;
    text-transform: uppercase;
    font-size: 58px;
    font-weight: 900;
    background-color: rgba(0, 0, 0, 0.5);
    display: inline;
    padding: 10px 20px;
}

@media (max-width: 767px) {
    .banner-title {
        font-size: 45px;
        padding: 5px 10px;
    }
}

@media (max-width: 575px) {
    .banner-title {
        font-size: 29px;
        padding: 5px 10px;
    }
}

.banner-text {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    max-width: 1170px;
    margin: 0 auto;
    width: 100%;
    z-index: 1;
    transform: translateY(-50%);
}

.banner-heading {
    text-align: center;
}

/* Slider pages */
.page-slider .carousel-control {
    position: absolute;
    top: 50%;
    z-index: 5;
    display: inline-block;
    transform: translateY(-50%);
    background-color: transparent;
    text-shadow: none;
    transition: all 0.25s ease;
    padding: 0;
    outline: 0;
    border: 0;
}

    .page-slider .carousel-control.left {
        left: 0;
    }

    .page-slider .carousel-control.right {
        right: 0;
    }

    .page-slider .carousel-control i {
        background: rgba(0, 0, 0, 0.3);
        color: #fff;
        line-height: 70px;
        width: 40px;
        height: 70px;
        font-size: 22px;
        border-radius: 0;
        transition: all 500ms ease;
    }

        .page-slider .carousel-control i:hover {
            background: #ffb600;
            color: #fff;
        }

.page-slider.small-bg .item {
    min-height: 330px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
}

.page-slider.small-bg .box-slider-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.page-slider.small-bg .box-slider-text {
    background: rgba(0, 0, 0, 0.55);
    padding: 5px 20px;
}

.page-slider.small-bg .box-slide-title {
    font-size: 28px;
    color: #fff;
    font-weight: 900;
}

/*-- Team slide--*/
.team-slide .slick-slide {
    margin-left: 25px;
    padding-bottom: 60px;
}

.team-slide .slick-list {
    margin-left: -25px;
}

.team-slide .carousel-control {
    position: absolute;
    bottom: 0;
    left: 50%;
    border: 0;
    background-color: #ddd;
    height: 37px;
    width: 37px;
    border-radius: 4px;
    transition: 0.3s;
    z-index: 6;
}

    .team-slide .carousel-control:hover {
        background-color: #ffb600;
        color: #fff;
    }

    .team-slide .carousel-control.left {
        transform: translateX(calc(-50% - 25px));
    }

    .team-slide .carousel-control.right {
        transform: translateX(calc(-50% + 25px));
    }

    .team-slide .carousel-control.slick-disabled {
        opacity: 0.4;
    }

        .team-slide .carousel-control.slick-disabled:hover {
            background-color: #ddd;
            color: #000;
        }

.ts-team-wrapper {
    position: relative;
    overflow: hidden;
}

    .ts-team-wrapper .ts-team-content {
        position: absolute;
        top: 76%;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 15px 25px;
        margin-top: 0;
        color: #fff;
        background: rgba(0, 0, 0, 0.5);
        transition: 350ms;
    }

@media (max-width: 1200px) {
    .ts-team-wrapper .ts-team-content {
        top: 72%;
    }
}

@media (max-width: 575px) {
    .ts-team-wrapper .ts-team-content {
        text-align: center;
    }
}

.ts-team-wrapper .ts-name {
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 3px;
    line-height: normal;
    color: #ffb600;
}

.ts-team-wrapper .ts-designation {
    color: #fff;
    font-size: 14px;
    margin-bottom: 15px;
    font-weight: 700;
}

.ts-team-wrapper .team-social-icons a i {
    color: #fff;
    margin-right: 8px;
    text-align: center;
    transition: 400ms;
}

    .ts-team-wrapper .team-social-icons a i:hover {
        color: #ffb600;
    }

.ts-team-wrapper:hover .ts-team-content {
    top: 0;
    padding-top: 50px;
}

.ts-description {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.ts-team-wrapper:hover .ts-description {
    opacity: 1;
}

.team-social-icons {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.ts-team-wrapper:hover .team-social-icons {
    opacity: 1;
}

img.w-100 {
    height: 50vh;
    object-fit: cover;
}

/* Pagination */
.paging {
    margin-top: 10px;
    margin-bottom: 10px;
}

    .paging ul.pagination {
        display: flex;
        justify-content: left;
        list-style: none;
        padding: 0;
        margin: 0;
    }

        .paging ul.pagination li.page-item {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            margin: 0;
            padding: 0;
        }

            .paging ul.pagination li.page-item a.page-link {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                padding: 0 10px;
                text-decoration: none;
                border: 1px solid #ddd;
                border-radius: 0 !important;
                color: #7c7c7c;
            }

                .paging ul.pagination li.page-item.active a.page-link,
                .paging ul.pagination li.page-item.active a.page-link:hover,
                .paging ul.pagination li.page-item a.page-link:hover {
                    color: #fff;
                    background: #ffb600;
                    border: 1px solid transparent;
                }

            .paging ul.pagination li.page-item.disabled a.page-link {
                pointer-events: none;
                opacity: 0.6;
            }

@media (max-width: 576px) {
    .paging ul.pagination li.page-item {
        height: 1.875rem;
    }

        .paging ul.pagination li.page-item a.page-link {
            padding: 0 0.3125rem;
            font-size: 0.75rem;
        }
}


/*table vertical alignments*/
.table-vertical-align-middle td,
.table-vertical-align-middle th {
    vertical-align: middle;
}

/*admin section*/
.preview {
    display: inline-block;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

    .preview:hover {
        color: #0056b3;
    }

.searchBar-Admin {
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 25rem;
}

/*-- Modal --*/
.modal-body-overflow {
    max-height: 70vh;
    overflow-y: auto;
}

/* effects */
.fade-in {
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

    .fade-in.visible {
        opacity: 1;
    }
